@import "../../assets_global/scss/partials/vars";

.home {
  header {
    .mobile-break {
      display: block;
      @media (min-width: 850px) {
        display: inline;
      }
    }

    br.mobile-break {
      display: none;
      @media (min-width: 850px) {
        display: block;
      }
    }
  }

  .callout {
    .title-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      z-index: 1;
      p {
        color: $color-white;
        width: 90%;
        margin: 0 auto;
      }
      h1 {
        font-weight: normal;
      }
    }
  }

  .bounce-arrow {
    display: block;
    position: absolute;
    bottom: 5%;
    width: 70px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    -webkit-transform-origin: center center;
    transform-origin: center center;
    // -webkit-animation: bounce 2s infinite;
    // animation: bounce 2s infinite;
    -webkit-animation: bounce 2s 1;
    animation: bounce 2s 1;

    transition: all 0.5s;
    &:hover {
      opacity: 0.8;
    }
  }

  .playlist {
    background-color: $color-white;
    @media (min-width: 850px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;
    }

    .playlist-copy {
      position: relative;
      width: 100%;
      min-height: 480px;
      padding: 60px 0 60px 0;
      //background:$color-purple url('../../assets_global/images/bg-texture.png');
      background: $color-white;

      @media (min-width: 900px) {
        padding: 75px 0;
      }

      .playlist-copy-wrapper {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        margin: 0 auto;
        width: 90%;
        //  transform: translate(-50%, -50%);
        @media (min-width: 850px) {
          width: 90%;
          padding: 0 15px;
        }

        @media (min-width: 1200px) {
          width: 75%;
        }
        p {
          text-align: left;
          font-size: 1em;
          color: $color-blue;
        }
      }
    }
    .playlist-spotify {
      position: relative;
      width: 100%;

      //min-height: 480px;
      // background:$headlines-1 url('../../assets_global/images/bg-texture.png');
      background: $color-blue;
      padding: 90px 0;
      p {
        font-size: 1em;
        color: $color-white;
        margin: 30px 0 5px 0;
      }

      .spotify-button {
        width: 115px;
        margin-right: 10px;

        &:hover {
          .st0 {
            fill: $color-black;
          }
          .st1 {
            stroke: $color-black;
          }
        }
      }

      .youtube-button {
        width: 121.7px;
        &:hover {
          .st0 {
            fill: $color-black;
          }
          .st1 {
            stroke: $color-black;
          }
        }
      }

      .iframe-wrapper {
        min-height: 480px;
      }
      iframe {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }
    }
  }

  .gradient-wrapper {
    //background: rgb(0,239,246);
    background-image: linear-gradient(
        0deg,
        rgba(0, 239, 246, 1) 0%,
        rgba(25, 172, 251, 1) 35%,
        rgba(55, 93, 254, 1) 100%
      ),
      url("../../assets_global/images/bg-texture.png");
    h2 {
      font-family: "Parabole";
      margin-top: 40px;
      font-weight: normal;
      font-size: 3em;
      text-align: left;
      line-height: 1;
    }
  }

  .no-gradient-wrapper {
    background-color: $color-black;
    h2 {
      font-family: "Parabole";
      margin-top: 40px;
      font-weight: normal;
      font-size: 3em;
      text-align: left;
      line-height: 0.8;
    }
  }

  .mental-health {
    h2 {
      color: $color-white;
    }
    .stat-wrapper {
      margin: 100px 0;
      .each-stat {
        text-align: left;
        margin-bottom: 30px;
        border-bottom: 1px solid $color-white;

        @media (min-width: 850px) {
          text-align: center;
          vertical-align: text-top;
          display: inline-block;
          border-right: 1px solid $color-white;
          border-bottom: none;
          min-height: 320px;
          width: 33%;
        }
        .stat {
          h3 {
            font-size: 30vw;
            color: $color-blue;
            font-weight: normal;
            margin: 0;
            @media (min-width: 850px) {
              font-size: 10vw;
            }
            @media (min-width: 1600px) {
              font-size: 10em;
            }
          }
          p {
            font-size: 1em;
            color: $color-white;
            margin-bottom: 60px;

            @media (min-width: 850px) {
              text-align: center;
              margin: 0 20px;
              border-bottom: none;
            }
          }
        }
      }
    }

    .each-stat:last-child {
      border-right: none;
    }
  }

  .creating-the-album {
    padding-bottom: 100px;
    background: $color-blue;
    z-index: 10;
    p {
      text-align: left;
      font-size: 1em;
      color: $color-white;
    }
    h2 {
      color: $color-white;
    }

    .album-content {
      text-align: left;
      margin-top: 50px;

      @media (min-width: 850px) {
        margin-top: 100px;
      }
      //  display:flex;
      //  flex-flow: column-reverse;
      //  justify-content:space-between;
      //  align-items:center;

      .number-output-wrapper {
        padding-bottom: 70px;
        @media (min-width: 850px) {
          display: inline-block;
          width: 30%;
          padding-top: 40px;
          padding-bottom: 0;
        }
        vertical-align: top;

        border-top: 1px solid $color-white;
        .number {
          display: inline-block;
        }

        .output {
          display: inline-block;
          text-transform: uppercase;
          text-align: center;
          margin-left: 25%;
        }
      }

      .output-description {
        display: inline-block;
        p {
          width: 100%;
          @media (min-width: 850px) {
            width: 80%;
          }
        }
        @media (min-width: 850px) {
          border-top: 1px solid $color-white;
          margin-left: 10%;
          padding-top: 40px;
          width: 60%;
        }
      }
    }

    .album-01 {
      margin-top: 100px;
      @media (min-width: 850px) {
      }
    }
  }

  .faq {
    background: $color-black;

    p,
    .collapsible {
      font-family: "MessinaSansMono", sans-serif;
      text-align: left;
      font-size: 1em;
      color: $color-white;
      display: block;
      width: 100%;
    }
    a,
    a:hover {
      color: #fff;
    }
    h2 {
      color: $color-white;

      @media (min-width: 850px) {
        position: absolute;
      }
    }
    .container {
      border-top: 1px solid $color-white;
      text-align: left;
    }

    .collapsible-wrapper {
      margin-top: 40px;

      .collapsible {
        color: $color-white;
        border-bottom: 1px solid $color-white;

        @media (min-width: 850px) {
          width: 60%;
          margin-left: 40%;
        }
        margin-bottom: 25px;
      }

      .collapsible:first-child {
        margin-top: 85px;
        @media (min-width: 850px) {
          margin-top: 0;
        }
      }
      .collapsible__trigger {
        display: block;
        width: 95%;
        //padding: 24px 50px 24px 24px;
        text-decoration: none;
        cursor: pointer;
        position: relative;
        padding-bottom: 60px;
      }

      .collapsible__contentInner {
        p {
          color: $color-grey;
          width: 80%;
        }
      }

      .collapsible__trigger:after {
        font-size: 1.2em;
        content: "+";
        position: absolute;
        right: -5%;
        top: -3px;
        display: block;
        transform-origin: center;

        transition: transform 300ms;
      }

      // .collapsible__trigger.is-open:hover:after {
      //   content:"-";
      // }

      .collapsible__trigger.is-open:after {
        content: "-";
        // transform-origin: center;
        // transform: rotateZ(45deg);
      }
      .collapsible__trigger.is-disabled {
        opacity: 0.5;
        background-color: grey;
      }
    }
  }

  .event {
    //overflow:visible;
    .container {
      //  margin:0 auto 0 auto;
      position: relative;
      // position:relative;
      //  margin-bottom:-20px;
      @media (min-width: 850px) {
        //   margin-bottom:-40px;
      }
    }

    .event-copy {
      position: relative;
      text-align: left;

      p {
        width: 100%;
        //  margin-top:22px;
        font-size: 1em;
        color: $color-white;
      }
      // display: inline-block;
      @media (min-width: 850px) {
        width: 65%;
        max-width: 850px;
        //  margin-left: 40%;
      }

      //margin-top: 40px;

      .learn-btn-link {
        display: inline-block;
        width: 150px;
      }
    }

    h2 {
      position: relative;
      color: $color-white;
      @media (min-width: 850px) {
        //   position: absolute;
      }
    }

    .title-date-wrapper {
      @media (min-width: 850px) {
        display: flex;
        justify-content: space-between;
      }
    }

    .event-date {
      margin-bottom: 40px;
      @media (min-width: 850px) {
        margin-bottom: 0;
      }
    }
    .learn-wrapper {
      text-align: left;
      margin-top: 60px;
      img {
        width: 150px;
        margin-right: 20px;
        @media (max-width: 400px) {
          margin-bottom: 30px;
        }
      }
    }
    .learn-btn-link {
      display: inline-block;
      width: 150px;
      //  margin-bottom:40px;
    }
  }

  .about {
    background: $color-black;
    //  background: url('../../assets_global/images/bg-texture.png');
    .about-copy {
      text-align: left;
      p {
        width: 100%;
        margin-top: 22px;
        font-size: 1em;
        color: $color-white;
      }
      display: inline-block;
      @media (min-width: 850px) {
        width: 60%;
        margin-left: 40%;
      }

      margin-top: 12px;
    }

    h2 {
      color: $color-white;
      @media (min-width: 850px) {
        position: absolute;
      }
    }
    .container {
      border-top: 1px solid $color-white;
    }

    // buttons
    .about-button-wrapper {
      margin: 50px 0 300px 0;
      text-align: left;

      .learn-wrapper {
        display: inline-block;
        .bridge-logo-link {
          display: inline-block;
          width: 120px;
        }
        .learn-btn-link {
          display: inline-block;
          width: 150px;
        }
      }
      @media (min-width: 850px) {
        width: 60%;
        margin-left: 40%;
      }
      a {
        // font-family: "Parabole", sans-serif;
        // display: inline;
        // font-size: 1.3em;
        // line-height: 1;
        // text-transform: uppercase;
        // color: $color-white;
        // text-decoration: none;
        // text-align: left;
        // border: 1px solid $color-white;
        // border-radius: 8px;
        // padding: 10px 10px 5px 10px;
        // margin: 0 3px;
        // text-transform: uppercase;
        // transition: all 0.5s;
      }
      a:hover {
        // color: $color-pink;
        // border: 1px solid $color-pink;
      }
    }
  }

  footer {
    width: 90%;
    margin: 0 auto;
    background: $color-black;
    .footer-wrapper {
      position: relative;
      height: 150px;
    }
    .site-title,
    .listen-on {
      font-family: "Parabole", sans-serif;
      color: $color-white;
      display: inline;
      font-size: 1.3em;
      line-height: 1;
      text-transform: uppercase;
    }

    .site-title {
      display: none;
      a {
        color: $color-white;
      }
      a:hover {
        color: $color-grey;
      }
      @media (min-width: 850px) {
        display: inline;
        padding-top: 0;
        position: absolute;
        text-align: left;
        left: 0;
        top: 40px;
        line-height: 0.9;
      }

      z-index: 10;
    }

    .listen-on {
      text-align: center;
      position: absolute;
      display: block;
      width: 100%;
      bottom: 40px;
      z-index: 10;

      @media (min-width: 850px) {
        display: inline;
        width: auto;
        right: 0;
        top: 50px;
      }

      .listen-on-wrapper {
        display: inline-block;
        vertical-align: text-top;
      }

      .footer-button-wrapper {
        display: inline-block;
        a {
          display: inline-block;
          width: 115px;
        }
        a:last-child {
          width: 128px;
        }
      }
    }
  }
}
