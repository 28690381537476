/* VARS - 27 CLUB WEBSITE ======================
======================================================= */
$color-white: #FFFFFF;
$color-black: #000000;
$color-grey: #7d7d7d;
$color-blue: #104FFF;
$color-blue-rollover: #134de7;
$color-yellow: #f7f257;
$color-pink: #ffbeff;
$color-purple: #782aff;
$headlines-1: #02ffff;

$desktop-nav-height: 100px;
$mobile-nav-height: 55px;