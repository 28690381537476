@import "_vars.scss";

@font-face {
  font-family: 'MessinaSansMono';
  src: url('../../fonts/MessinaSansMonoWeb-Regular.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/MessinaSansMonoWeb-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/MessinaSansMonoWeb-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/MessinaSansMonoWeb-Regular.woff') format('woff');
}

@font-face {
  font-family: 'MessinaSansRegular';
  src: url('../../fonts/MessinaSansWeb-Regular.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/MessinaSansWeb-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/MessinaSansWeb-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/MessinaSansWeb-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Parabole';
  src: url('../../fonts/parabole-regular-webfont.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/parabole-regular-webfont.woff') format('woff');
}

html{
  position:relative;
  height:100%;/* make sure it is at least as tall as the viewport */
  scroll-behavior: smooth;
}
body{
  min-height:100%; /* force the BODY element to match the height of the HTML element */
  background-color:#000;
}

#root {
  position:relative;
  min-height: 100%;
  z-index: 1;
}

.wrapper {
  position: relative;
 // overflow: hidden;
}

// General blockwrap, wrapper for full width containers, always 100% width
.blockwrap {
  display:block;
  position: relative;
  overflow:hidden;
  width: 100%;
  text-align: center;
}

.container {
  display: block;
  max-width: 90%;
  margin:0 auto;
  padding:0;
  border-top:1px solid $color-white;
  margin: 80px auto 0 auto;
  @media(min-width:768px) {
  //  margin: 160px auto;
  }
  text-align: center;

  // Create some padding via 90% width
  @media (max-width: 1050px){
    width: 90%;
  }
  @media(min-width:850px) {
    margin: 150px auto 0 auto;

  }
  // Make full width for Mobile devices
  @media (max-width: 576px){
    width: 100%;
  }
  
}

h1 {
  margin:0;
  font-size:12vw;
  color:$color-white;
  font-family:"Parabole";
  line-height:.8em;
  text-transform:uppercase;
  @media(min-width:450px) {
    font-size:11vw;
  }
  @media(min-width:750px) {
    font-size:9.8vw;
  }
  @media(min-width:850px) {
    font-size:8.8vw;
  }
}


h2 {
  font-size:14vw;
  color:$color-blue;
  margin-left:-3px;

  @media(min-width: 768px) 
  {
    // font-size: 8vw;
  }
  @media(min-width: 1024px) 
  {
    // font-size: 5.5em;
  }
  // line-height:1em;
  text-transform:uppercase;
}

h3 {
  text-transform:uppercase;
  font-size:8vw;
  color:$color-black;

  @media(min-width: 600px) 
  {
    font-size: 2.5em;
  }

  @media(min-width: 1024px) 
  {
    font-size: 2.8em;
  }
}

p {
  font-family: 'MessinaSansMono', sans-serif;
  font-size:1.2em;
  color:$color-black;
  line-height:1.8em;
  @media(min-width: 768px) {
    font-size:1.2em;
  }
}
 
a {
  transition: 0.2s ease all;
  outline: none;
  border: none;
  color:$color-grey;
}

a:hover {
  text-decoration:none;
  color:$color-white;
}

.messinasans {
  font-family:"MessinaSansRegular";
}

.fix-spacing {
  letter-spacing:-4px;
}

.title-fix-spacing {
  letter-spacing:-10px;
  @media(min-width:1200px) {
    letter-spacing:-12px;

  }
}

.mb-break {
  display:block;
  @media(min-width:850px) {
    display:none;
  }
}

.parabole {
  font-family:"Parabole";
}

.side-half{
  display: inline-block;
  width: 50%;
  vertical-align: top;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.show {
  display: block;
}
.hide {
  display: none!important;
}
.not-visible {
  visibility: hidden !important;
  opacity: 0 !important;
  transition: opacity 3.25s ease-in-out;
}

html:lang(en) {
  br.only-fr {
    display: none;
  }
  br.only-en {
    display: inline-block;
  }
} 
html:lang(fr) {
  br.only-fr {
    display: inline-block;
  }
  br.only-en {
    display: none;
  }
} 